import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Gallery } from './Gallery'

export interface Props {
  cta?: ButtonProps
  description?: string
  images?: ImageProps[]
  label?: string
  mainImage?: ImageProps
  title?: string
}

export const Item = memo(function Item({
  cta,
  description,
  images,
  label,
  mainImage,
  title,
}: Props) {
  const [galleryRef, isGalleryVisible] = useInView({ threshold: 0.4 })
  const [labelRef, isLabelVisible] = useInView({ threshold: 0.25 })

  return (
    <Container row stretch wrap>
      <LeftSide
        className={isGalleryVisible ? 'visible' : undefined}
        ref={galleryRef}
      >
        {images && images.length > 0 ? <Gallery images={images} /> : null}
      </LeftSide>

      <RightSide>
        <Wrapper>
          {mainImage ? (
            <MainImage className="image">
              <LazyLoadComponent>
                <Image {...mainImage} />
              </LazyLoadComponent>
            </MainImage>
          ) : null}

          {Label ? (
            <Label
              ref={labelRef}
              className={`label${isLabelVisible ? ' visible' : ''}`}
            >
              {label}
            </Label>
          ) : null}
        </Wrapper>

        {title ? (
          <FadeInUp>
            <Title>
              <InnerTitle>{title}</InnerTitle>
            </Title>
          </FadeInUp>
        ) : null}

        {description ? (
          <FadeInUp>
            <Description
              className="item-desc"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </FadeInUp>
        ) : null}

        {cta ? (
          <FadeInUp>
            <CTA {...cta} />
          </FadeInUp>
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin-top: 9.375rem;
  &:first-of-type {
    margin-top: 11.875rem;
  }
  &:nth-of-type(even) {
    > div {
      &:first-of-type {
        order: 2;
        &.visible {
          &:before {
            right: 100%;
            left: 0;
          }
        }
      }
    }
    .image {
      margin-left: 0;
    }
    .label {
      right: 0;
      left: auto;
    }
  }

  @media (max-width: 1199px) {
    margin-top: 6.25rem;
    &:first-of-type {
      margin-top: 3.75rem;
    }
    &:nth-of-type(even) {
      > div {
        &:last-of-type {
          order: 2;
        }
      }
      .label {
        right: 1.5rem;
      }
    }
  }
`

const LeftSide = styled.div`
  width: 50%;
  position: relative;
  &:before {
    content: '';
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    top: 0;
    right: 8.333vw;
    left: 8.333vw;
    transition: 2.5s cubic-bezier(0.76, 0, 0.24, 1);
    z-index: 2;
  }
  &.visible {
    &:before {
      left: 100%;
    }
  }

  @media (max-width: 1199px) {
    width: 100%;
    &:before {
      display: none;
    }
  }
`

const RightSide = styled.div`
  width: 50%;
  padding: 0 8.333vw 0 5.208vw;
  text-align: left;

  @media (max-width: 1199px) {
    width: 100%;
    padding: 2.5rem 1.5rem 0;
  }
`

const Wrapper = styled.div`
  height: 25.1vh;
  max-height: 14.125rem;
  margin-bottom: 4.375rem;
  position: relative;

  @media (max-width: 1199px) {
    height: auto;
    margin-bottom: 0;
    position: static;
  }
`

const MainImage = styled.div`
  width: 11.806vw;
  max-width: 10.625rem;
  height: 100%;
  margin-left: auto;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    display: none;
  }
`

const Label = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.1875rem;
  font-weight: 300;
  line-height: 1.875rem;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  writing-mode: vertical-rl;
  transform: scaleX(-1) scaleY(-1) translateY(-3.75rem);
  transition: 0.5s ease-in-out;
  &.visible {
    opacity: 1;
    transform: scaleX(-1) scaleY(-1);
  }

  @media (max-width: 1199px) {
    top: 1.875rem;
    right: 1.5rem;
    left: auto;
    writing-mode: horizontal-tb;
    text-overflow: ellipsis;
    transform: none;
    &.visible {
      transform: none;
    }
  }
`

const Title = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.8125rem;
  font-weight: 300;
  line-height: 3.375rem;
  white-space: nowrap;
  &:after {
    content: '';
    display: inline-block;
    width: 2.5rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark3};
    margin-left: 0.9375rem;
    transform: translateY(-0.75rem);
  }

  @media (max-width: 1199px) {
    font-size: 2.1875rem;
    line-height: 2.625rem;
    white-space: normal;
    &:after {
      width: 1.875rem;
      transform: translateY(-0.5rem);
    }
  }
`

const InnerTitle = styled.span`
  display: inline;
  white-space: normal;
`

const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 200;
  line-height: 2.125rem;
  margin-top: 1.875rem;

  @media (max-width: 1199px) {
    font-size: 1.125rem;
    line-height: 2.125rem;
  }
`

const CTA = styled(Button)`
  margin-top: 5rem;

  @media (max-width: 1199px) {
    margin-top: 3.125rem;
  }
`
