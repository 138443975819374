import styled from '@emotion/styled'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  videoURL?: string
}

export const BannerVideo = memo(function BannerVideo({ videoURL }: Props) {
  if (!videoURL) {
    return null
  }

  return (
    <Container>
      <LazyLoadComponent>
        <Video autoPlay loop muted>
          <Source src={videoURL} type="video/mp4" />
        </Video>
      </LazyLoadComponent>
    </Container>
  )
})

const Container = styled.section`
  height: 82.2vh;
  max-height: 46.25rem;
  overflow: hidden;
  position: relative;

  @media (max-width: 1199px) {
    height: 19.375rem;
  }
`

const Video = styled.video`
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Source = styled.source``
