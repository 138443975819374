import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Dot } from 'app/components/Common/Dot'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Spinner } from 'app/components/Common/Spinner'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useState } from 'react'

export interface Props {
  images?: ImageProps[]
}

export const Gallery = memo(function Gallery({ images }: Props) {
  if (!images) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)
  const [details, setDetails] = useState<any>(null)
  const [loaded, setLoaded] = useState<boolean[]>([])
  const [nextLoaded, setNextLoaded] = useState<boolean[]>([])

  function positionStyle(idx: any) {
    if (!details) {
      return undefined
    }

    const position = details.slides[idx].distance
    const x = (galleryRef.current?.size || window.innerWidth) * -position
    return {
      transform: `translate3d(${x}px, 0px, 0px)`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
    }
  }

  const [sliderRef, galleryRef] = useKeenSlider({
    defaultAnimation: {
      duration: 1800,
    },
    loop: images.length > 1 ? true : false,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      images.length > 1 ? setDetails(s.track.details) : null
    },
  })

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true
    const newNextLoaded = [...nextLoaded]
    newNextLoaded[currentSlide + 1] = true

    setLoaded(newLoaded)
    setNextLoaded(newNextLoaded)

    setLoaded(newLoaded)
  }, [currentSlide, galleryRef, sliderRef])

  return (
    <Container>
      <Carousel ref={sliderRef}>
        {uniq(images).map((item, index) => (
          <Slide key={index} className="keen-slider__slide">
            <Inner style={positionStyle(index)}>
              <Image
                alt={loaded[index] || nextLoaded[index] ? item.alt : ''}
                animationStart={
                  loaded[index] || nextLoaded[index] ? true : false
                }
                {...(loaded[index] || nextLoaded[index] ? item : null)}
              />

              <Spinner />
            </Inner>
          </Slide>
        ))}
      </Carousel>

      {images.length > 1 ? (
        <Dots>
          {images.map((_item, index) => (
            <Dot
              key={index}
              className={currentSlide === index ? 'active' : undefined}
              onClick={() => {
                galleryRef.current?.moveToIdx(index)
              }}
            />
          ))}
        </Dots>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 7.5rem 8.333vw;
  position: relative;

  @media (max-width: 1199px) {
    padding: 5.375rem 1.5rem;
  }
`

const Carousel = styled.div`
  display: flex;
  width: 100%;
  height: 58.7vh;
  max-height: 33rem;
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  outline: none;
  overflow: hidden;
  position: relative;
  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }

  @media (max-width: 1199px) {
    height: 17.5rem;
  }
`

const Slide = styled.div`
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition-timing-function: cubic-bezier(0.76, 0, 0.24, 1);
`

const Dots = styled.div`
  position: absolute;
  bottom: 7.5rem;
  right: 3.958vw;

  > div {
    margin: 3.125rem 0 0;
  }

  @media (max-width: 1199px) {
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 2.5rem;
    right: 0;
    left: 0;

    > div {
      margin: 0 0.9375rem;
    }
  }
`
