import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
  label?: string
  title?: string
}

export const ZigZagCompact = memo(function ZigZagCompact({
  items,
  label,
  title,
}: Props) {
  if (items.length < 1) {
    return null
  }

  return (
    <Container>
      {label ? (
        <FadeInUp>
          <Label>{label}</Label>
        </FadeInUp>
      ) : null}

      {title ? (
        <FadeInUp>
          <Title>
            <InnerTitle>{title}</InnerTitle>
          </Title>
        </FadeInUp>
      ) : null}

      <List>
        {items.map((item, index) => (
          <Item key={index} {...item} />
        ))}
      </List>
    </Container>
  )
})

const Container = styled.section`
  font-weight: 300;
  margin: 9.375rem auto;
  text-align: center;

  @media (max-width: 1199px) {
    margin: 6.25rem auto;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.1875rem;
  line-height: 1.875rem;
  margin-bottom: 2.5rem;

  @media (max-width: 1199px) {
    margin-bottom: 1.25rem;
    padding: 0 1.5rem;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 6.25rem;
  line-height: 7.75rem;
  white-space: nowrap;
  &:after {
    content: '';
    display: inline-block;
    width: 4.5rem;
    height: 0.25rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark3};
    margin-left: 1.25rem;
    transform: translateY(-1.125rem);
  }

  @media (max-width: 1199px) {
    font-size: 2.5rem;
    line-height: 2.8125rem;
    padding: 0 1.5rem;
    &:after {
      width: 1.875rem;
      height: 0.125rem;
      transform: translateY(-0.5rem);
    }
  }

  @media (max-width: 374px) {
    &:after {
      display: none;
    }
  }
`

const InnerTitle = styled.span`
  display: inline;
  white-space: normal;
`

const List = styled.div``
