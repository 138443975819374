import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  className?: string
  onClick?: (e: any) => void
}

export const Dot = memo(function Dot({ className, onClick }: Props) {
  return <Container className={className} onClick={onClick} />
})

const Container = styled.div`
  width: 0.375rem;
  height: 0.375rem;
  background: ${({ theme }) => theme.colors.variants.neutralDark6};
  border-radius: 50%;
  cursor: pointer;
  margin-right: 3.125rem;
  opacity: 0.15;
  &.active {
    opacity: 1;
  }
`
